import fire from "../../../services/fire";

export const priestimages = [
  { id: 0, temp: true, photo: require("../../../img/priest/arch.jpg") },
  { id: 1, photo: require("../../../img/priest/blessedsacrament.jpg") },
  { id: 2, temp: true, photo: require("../../../img/priest/cathedral.jpg") },
  {
    id: 3,
    temp: true,
    photo: require("../../../img/priest/ctk.jpg"),
  },
  { id: 4, photo: require("../../../img/priest/divinemercy.jpg") },
  { id: 5, photo: require("../../../img/priest/holycross.jpg") },
  { id: 6, photo: require("../../../img/priest/holyfamily.jpg") },
  { id: 7, temp: true, photo: require("../../../img/priest/holyspirit.jpg") },
  { id: 8, temp: true, photo: require("../../../img/priest/holytrinity.jpg") },
  { id: 9, photo: require("../../../img/priest/immaculateheartofmary.jpg") },
  { id: 10, photo: require("../../../img/priest/ladyoflourdes.jpg") },
  {
    id: 11,
    temp: true,
    photo: require("../../../img/priest/nativityofblessedvirgin.jpg"),
  },
  {
    id: 12,
    temp: true,
    photo: require("../../../img/priest/perpertualsuccour.jpg"),
  },
  {
    id: 13,
    temp: true,
    photo: require("../../../img/priest/queenofpeace.jpg"),
  },
  {
    id: 14,
    temp: true,
    photo: require("../../../img/priest/starofthesea.jpg"),
  },
  { id: 15, photo: require("../../../img/priest/risenchrist.jpg") },
  { id: 16, photo: require("../../../img/priest/sacredheart.jpg") },
  { id: 17, temp: true, photo: require("../../../img/priest/novena.jpg") },
  { id: 18, temp: true, photo: require("../../../img/priest/stanne.jpg") },
  { id: 19, temp: true, photo: require("../../../img/priest/stanthony.jpg") },
  { id: 20, photo: require("../../../img/priest/stbernadette.jpg") },
  { id: 21, photo: require("../../../img/priest/stfrancisassisi.jpg") },
  { id: 22, photo: require("../../../img/priest/stfrancisxavier.jpg") },
  { id: 23, photo: require("../../../img/priest/stignatius.jpg") },
  { id: 24, photo: require("../../../img/priest/stjosephbukittimah.jpg") },
  {
    id: 25,
    temp: true,
    photo: require("../../../img/priest/stjosephvictoria.jpg"),
  },
  {
    id: 26,
    temp: true,
    photo: require("../../../img/priest/stmaryofangels.jpg"),
  },
  { id: 27, temp: true, photo: require("../../../img/priest/stmichael.jpg") },
  { id: 28, temp: true, photo: require("../../../img/priest/ststephen.jpg") },
  { id: 29, temp: true, photo: require("../../../img/priest/stteresa.jpg") },
  { id: 30, photo: require("../../../img/priest/stvincentdepaul.jpg") },
  { id: 31, photo: require("../../../img/priest/stspeterandpaul.jpg") },
  {
    id: 32,
    temp: true,
    photo: require("../../../img/priest/transfiguration.jpg"),
  },
  { id: 33, temp: true, photo: require("../../../img/priest/arch.jpg") },
];

export function getPriestImages() {
  return priestimages;
}

export function getParishPriestImageById(id) {
  const [priest] = priestimages.filter((g) => g.id === parseInt(id));
  //override here, if none, use parish church photo
  let photo = priest.photo;
  //   if (priest.temp) {
  //     photo = "temp";
  //   }
  return photo;
}

export async function getPriests(sort = true) {
  const orgs = await fire
    .firestore()
    .collection(`person`)
    .where("status", "!=", "")
    .get();

  const results = [];
  orgs.forEach((entry) => {
    const data = entry.data();
    results.push({ ...data, id: data.id });
  });

  if (sort === true && results.length > 0) {
    results.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  return results;
}

export async function savePriest(data, empty) {
  const dataRef = fire.firestore().collection("person").doc();
  let obj;
  if (empty) {
    //this is a fresh data
    obj = { ...data, uid: dataRef.id };
    await dataRef.set(obj);
  } else {
    const lastUpdate = new Date().getTime();
    obj = { ...data, lastUpdate, uid: dataRef.id };
    await dataRef.update(obj);
  }
  return obj;
}

export async function updatePriestById(id, data) {
  const entryRef = fire.firestore().collection(`person`);
  const entryData = await entryRef.where("uid", "==", id).get();
  const result = [];
  const lastUpdate = new Date().getTime();

  entryData.forEach(async (i) => {
    const item = i.data();
    //get the id
    result.push({ uid: item.uid, lastUpdate, ...data });
    await entryRef.doc(item.uid).update({ uid: item.uid, lastUpdate, ...data });
  });

  return result[0];
}
