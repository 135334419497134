import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getObjectSettingsDB } from "../../services/objects";
import { logEvent } from "../../services/log";

const divStyle = {
  height: "124px",
  border: "2px dashed rgb(255 196 16)",
  background:
    "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,249,231,1) 0%, rgba(255,252,246,1) 22%, rgba(255,252,246,1) 48%, rgba(255,242,201,1) 75%, rgba(252,239,188,1) 98%)",
};

function CustomBanner({ link = "/", code = "" }) {
  const [show, setShow] = useState(false);
  const [objProps, setObjProps] = useState({});

  useEffect(() => {
    async function getData() {
      const obj = await getObjectSettingsDB();
      const show = obj?.frontbanner?.showonweb || false;
      setShow(show);
      setObjProps(obj?.frontbanner || {});
    }

    getData();
  }, []);

  if (!show) {
    return null;
  }

  const handleClick = () => {
    logEvent(`dco_job_${code}`, { type: "DCO" });
  };

  return (
    <Link onClick={handleClick} to={link}>
      <div className="mb-3 rounded shadow-md pointer" style={divStyle}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="mt-2 text-left" style={{ height: "100px" }}>
            <img
              id="imgleft"
              src={objProps?.leftimage}
              className="pl-3 responsive"
              style={{ height: "50px", maxHeight: "50px" }}
              alt=""
            />
            <div>
              <button className="py-1 mediumfontsize mt-2 btn btn-md btn-warning ml-3 font-weight-bold px-5">
                <div className="px-1">{objProps?.buttonlabel}</div>
              </button>
            </div>
          </div>
          <div
            className="d-none d-sm-none d-md-block d-lg-block  "
            style={{ width: "60px" }}
          ></div>
          <div style={{ overflow: "hidden" }}>
            <img
              id="imgright"
              src={objProps?.rightimage}
              className="mt-2 responsive"
              style={{ maxHeight: "102px", minWidth: "100px" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CustomBanner;
