import React, { useRef, useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
import { TransfersDatum, RegistrationStatus } from "../../hooks/hooks";
import {
  useTransfers,
  TransfersStatus,
  // QueriedAdminContext,
  useRowFilters,
  useQueriedAdminRerenderer,
  useRegistrations,
  useLookupConfig,
} from "../hooks/hooks";

import {
  getListProps,
  intFsmEdges,
  TransfersAction,
} from "../services/transfersTable";
import { transfersStatusDictionary } from "../../services/transfersStatus";

// import { getParishNameById } from "../../../../../services/parish";
import fire from "../../../../../services/fire";
import AppGlobalContext from "../../../../../AppGlobalContext";
import AbstractList from "../components/AbstractList";
import {
  registrationCollectionName,
  transfersCollectionName,
} from "../../services/collections";

import RegistrationDetails from "../components/RegistrationDetails";
import TransferDetails from "../components/TransferDetails";
import {
  registrationStatusNameToStatus,
  registrationStatusToStatusName,
} from "../../services/registrationStatus";
import {
  isSuperAdminRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
} from "../../../../roles/service/roles";

const createCatchRegistration = fire
  .functions("asia-east2")
  .httpsCallable("createCatchRegistration");

export default function TransfersListInt() {
  const rerenderQueriedAdminState = useQueriedAdminRerenderer();
  const lookupConfig = useLookupConfig();
  const registrationsState = useRegistrations();
  const transfersState = useTransfers();
  const rowFilters = useRowFilters();

  const { user } = useContext(AppGlobalContext);

  const isSuperAdmin = isSuperAdminRole(user);
  const isCatchOverallAdmin = isCATCHAdminRole(user);
  const isCatchParishAdmin = isCATCHParishAdminRole(user);

  const [selectedNotes, setSelectedNotes] = useState([]);

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const transfersCollection = db.collection(transfersCollectionName);

  /** @type {{value: TransfersStatus}[]} */
  const radios = [
    { value: "pendingOut" },
    { value: "rejectedOut" },
    { value: "pendingIn" },
    { value: "rejectedIn" },
    { value: "accepted" },
  ];

  /** @type {TransfersDatum[]} */
  const initialSelectedRows = [];
  const selectedRowsRef = useRef(initialSelectedRows);

  /**
   * @param {TransfersAction} action
   * @param {TransfersStatus} currentStatus
   */
  async function handleAction(action, currentStatus) {
    const selectedCount = selectedRowsRef.current.length;

    if (selectedCount === 0) {
      alert("Please select some rows first!");
      return Promise.resolve();
    }

    /** @type {TransfersStatus} */
    let newStatus;
    /** @type {RegistrationStatus} */
    let currentRegistrationStatus;
    /** @type {RegistrationStatus} */
    let newRegistrationStatus;
    /** @type {Record<TransfersStatus, RegistrationStatus>} */
    const transferToRegistrationStatus = {
      pendingOut: "pending",
      rejectedOut: "rejected",
      pendingIn: "pending",
      rejectedIn: "rejected",
      accepted: "transferred",
    };

    // eslint-disable-next-line default-case
    switch (action) {
      case "accept":
      case "reject":
      case "unassign":
        newStatus = intFsmEdges[action][currentStatus];
        currentRegistrationStatus = transferToRegistrationStatus[currentStatus];
        newRegistrationStatus = transferToRegistrationStatus[newStatus];

        const batch = db.batch();
        let updatedCount = 0;

        const toUpdateTransfers = [];
        const toUpdateRegistrations = [];
        const toCreateRegistrations = [];

        for (const row of selectedRowsRef.current) {
          let registrationUpdate = {};
          let transferUpdate = {};

          if (currentRegistrationStatus !== newRegistrationStatus)
            registrationUpdate = {
              ...registrationUpdate,
              status: registrationStatusNameToStatus(newRegistrationStatus),
            };

          if (
            action === "reject" ||
            newStatus === "accepted" ||
            !transfersState.data.some(
              ({ submittedBy, status, child = {} }) =>
                status === newStatus &&
                submittedBy === row.submittedBy &&
                child.uid === row.child.uid
            )
          ) {
            transferUpdate = {
              ...transferUpdate,
              status: newStatus,
            };

            if (action === "accept") {
              if (currentStatus === "pendingOut" && newStatus === "pendingIn") {
                transferUpdate = {
                  ...transferUpdate,
                  outProcessedAt: new Date(),
                  outProcessedBy: user.uid,
                  outProcessedByName: user.displayName,
                };
              } else if (
                currentStatus === "pendingIn" &&
                newStatus === "accepted"
              ) {
                const registration = row.registration;
                const transferTo = row.to;

                if (isSuperAdmin || isCatchOverallAdmin) {
                  const newRegistrationDoc = registrationCollection.doc();

                  const docData = { ...registration };

                  docData.id = newRegistrationDoc.id;
                  docData.registrationType = "transfer";
                  docData.selectedParishId = transferTo?.id || "";
                  docData.programmeType = transferTo?.programmeId || "";
                  docData.timeslot = transferTo?.timeslotId || "";
                  docData.assignedClass = "";
                  docData.status = 0;

                  batch.set(newRegistrationDoc, docData);
                } else {
                  const { data: { ok, message, doc, error } = {} } =
                    await createCatchRegistration({
                      uid: user.uid,
                      parish: user.parish,
                      registration,
                      transferTo,
                    });

                  if (ok) {
                    // console.log(message);
                    toCreateRegistrations.push(doc);
                  } else {
                    console.error(error);
                  }
                }
              }
            }
            transfersState.data.find(({ id }) => id === row.id).status =
              newStatus;
            updatedCount++;
          }

          if (Object.keys(transferUpdate).length > 0) {
            toUpdateTransfers.push([row.id, transferUpdate]);
            batch.update(transfersCollection.doc(row.id), transferUpdate);
          }
          if (Object.keys(registrationUpdate).length > 0) {
            toUpdateRegistrations.push([
              row.registration.id,
              registrationUpdate,
            ]);
            batch.update(
              registrationCollection.doc(row.registration.id),
              registrationUpdate
            );
          }
        }

        if (updatedCount === 0) {
          alert(
            `There are ${newStatus} transfers in conflict with your selected transfers.`
          );
        } else {
          await batch.commit();

          for (const [id_, update] of toUpdateTransfers) {
            const docToUpdate = transfersState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          for (const [id_, update] of toUpdateRegistrations) {
            if (
              registrationStatusToStatusName(update.status) === "transferred"
            ) {
              // Do not display transfer cases in Registrations Table
              const docToDeleteIndex = registrationsState.data.findIndex(
                ({ id }) => id === id_
              );
              if (docToDeleteIndex !== -1)
                registrationsState.data.splice(docToDeleteIndex, 1);
            } else {
              const docToUpdate = registrationsState.data.find(
                ({ id }) => id === id_
              );
              if (docToUpdate)
                for (const [key, value] of Object.entries(update))
                  docToUpdate[key] = value;
            }
          }

          registrationsState.data.push(...toCreateRegistrations);
          registrationsState.exists =
            registrationsState.exists || toCreateRegistrations.length > 0;

          rerenderQueriedAdminState();

          alert(
            updatedCount === selectedCount
              ? "Update successful!"
              : `Update partially successful!\nThere are ${newStatus} transfers in conflict with some of your selected transfers.`
          );
        }

        break;
      case "email":
        let emails = "mailto:";
        if (selectedRowsRef.current.length === 1) {
          emails += selectedRowsRef.current[0].registration.mainContact.email;

          if (
            selectedRowsRef.current[0].registration.secondaryContact.exists &&
            selectedRowsRef.current[0].registration.secondaryContact
              .emergencies === false
          ) {
            emails += `;${selectedRowsRef.current[0].registration.secondaryContact.email}`;
          }
        } else {
          emails += "?bcc=";
          selectedRowsRef.current.forEach((row, index) => {
            emails += row.registration.mainContact.email;

            if (
              row.registration.secondaryContact.exists &&
              row.registration.secondaryContact.emergencies === false
            ) {
              emails += `;${row.registration.secondaryContact.email}`;
            }

            if (index < selectedRowsRef.current.length - 1) {
              emails += ";";
            }
          });
        }

        window.location.href = emails;
        break;
    }
    return Promise.resolve();
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [cachedTransfers, setCachedTransfers] = useState([]);

  useEffect(() => {
    setCachedTransfers(transfersState.data || []);
  }, [transfersState.data]);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
    // console.log(item.id);
    // console.log(item.registration.id);

    if (item.notes) {
      item.notes.map((note) => {
        if (note.edit) {
          note.edit = false;
        }
      });
      setSelectedNotes(item.notes);
    }
  };

  const updateNotes = (itemId, newNotes) => {
    setCachedTransfers((prev) => {
      return prev.map((item) =>
        item.id === itemId
          ? {
              ...item,
              notes: newNotes,
              registration: {
                ...item.registration,
                notes: newNotes,
              },
            }
          : item
      );
    });
  };

  const closeModal = () => {
    if (selectedItem?.id) {
      updateNotes(selectedItem.id, selectedNotes);
    }
    setModalIsOpen(false);
    setSelectedItem(null);
    setSelectedNotes([]);
  };

  useEffect(() => {
    if (selectedItem?.registration?.notes) {
      setSelectedNotes(selectedItem.registration.notes);
    } else {
      setSelectedNotes([]);
    }
  }, [selectedItem]);

  const dataProps = cachedTransfers.filter(
    ({ status, from, to, registration: { level } }) => {
      return (
        ("" === rowFilters.programme ||
          (from.id === rowFilters.parish &&
            from.programmeId === rowFilters.programme) ||
          (to.id === rowFilters.parish &&
            to.programmeId === rowFilters.programme)) &&
        ("" === rowFilters.level || level === rowFilters.level) &&
        ("" === rowFilters.status || status === rowFilters.status)
      );
    }
  );
  const viewButtonProps = {
    openModal,
    selectedItem,
  };

  return (
    <>
      <AbstractList
        {...getListProps(lookupConfig, viewButtonProps)}
        id="transfers-table"
        statusDictionary={transfersStatusDictionary}
        radios={radios}
        currentStatus={rowFilters.status}
        rawDataProps={dataProps}
        selectedRowsRef={selectedRowsRef}
        handleAction={handleAction}
      />

      {transfersState.exists
        ? transfersState.data.map((item) => {
            const name = `${item.id || ""}-${item.from.id || ""}-${
              item.to.id || ""
            }`;

            return (
              <button
                key={name}
                id={`button-${name}`}
                style={{ display: "none" }}
                disabled={modalIsOpen}
                onClick={() => {
                  if (!modalIsOpen) {
                    // console.log(item);
                    openModal(item);
                  }
                }}
              />
            );
          })
        : null}

      <Modal
        className="p-0"
        dialogClassName="modal-fullscreen"
        show={modalIsOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="font-weight-bold noprint">
          <div className="w-100 d-flex justify-content-center">
            <Modal.Title>{selectedItem?.registration?.name}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "30px" }}>
            <TransferDetails {...{ user, selectedItem, external: false }} />
          </div>
          <hr />
          <div style={{ padding: "30px" }}>
            <RegistrationDetails
              {...{
                user,
                registrationCollection,
                transfersCollection,
                selectedItem: selectedItem?.registration,
                setSelectedItem: (registration) =>
                  setSelectedItem({ ...selectedItem, registration }),
                selectedNotes,
                setSelectedNotes,
                currentContext: "transfers",
                transferDocId: selectedItem?.id,
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
