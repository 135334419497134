import React, { useContext, useRef, useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";

import {
  RegistrationStatus,
  RegistrationDatum,
  RegistrationState,
} from "../../hooks/hooks";
import {
  useIsMounted,
  useRegistrations,
  useRowFilters,
  useQueriedAdminRerenderer,
  useTransfers,
  LookupConfig,
  useLookupConfig,
} from "../hooks/hooks";

import {
  isSuperAdminRole,
  isCATCHAdminRole,
  isCATCHParishAdminRole,
} from "../../../../roles/service/roles";
import {
  registrationCollectionName,
  catchConfirmationFormsCollectionName,
  transfersCollectionName,
} from "../../services/collections";
import {
  getParishAdminListProps,
  getOverallAdminListProps,
  RegistrationAction,
} from "../services/registrationTable";
import { downloadCert } from "../services/downloadCert";
import {
  guardianRegistrationStatusDictionary,
  registrationStatusNameToStatus,
} from "../../services/registrationStatus";
import { getCurrentLevel } from "../../services/levels";
import { registrationStatusFilterOptions } from "../services/tableUtils";

import { sendGenericHTMLEmail } from "../../../../email/emailTemplateHelpers";
import AppGlobalContext from "../../../../../AppGlobalContext";

import fire from "../../../../../services/fire";
import AbstractList from "../components/AbstractList";
import MoveClassModal from "../components/MoveClassModal";

import RegistrationDetails from "../components/RegistrationDetails";

import "./adminList.css";

const getCatchConfirmations = fire
  .functions("asia-east2")
  .httpsCallable("getCatchConfirmations");

/**
 * Assigns selected items to classes based on timeslots and availability.
 *
 * @param {RegistrationDatum[]} selectedItems - The items to be assigned to classes.
 * @param {LookupConfig} lookupConfig - The function to lookup configuration based on selected parish ID.
 * @param {RegistrationState} registrationState - The state of registration data.
 * @return {void}
 */
async function autoAssignClass(selectedItems, lookupConfig, registrationState) {
  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);

  // Group by timeslot and sort
  const timeslotRegistrations = selectedItems.reduce((group, item) => {
    const { timeslot, selectedParishId, programmeType } = item;
    group[timeslot] = group[timeslot] ?? {};

    if (Object.keys(group[timeslot]).length === 0) {
      group[timeslot] = {
        items: [item],
        selectedParishId,
        selectedProgrammeId: programmeType.id,
      };
    } else {
      group[timeslot].items.push(item);
    }
    group[timeslot].items.sort((data) => data.submittedAt);
    return group;
  }, {});

  for (const [key, selectedData] of Object.entries(timeslotRegistrations)) {
    let selectedProgramme,
      selectedProgrammeTimeslot,
      minCount = 0,
      minClassIndex = 0;

    const { parish } = lookupConfig(selectedData.selectedParishId);

    selectedProgramme = parish
      ?.data()
      .programmes.find(({ id }) => id === selectedData.selectedProgrammeId);

    try {
      selectedProgrammeTimeslot = selectedProgramme.timeslots.find(
        ({ name }) => name === key
      );

      for await (const [
        index,
        data,
      ] of selectedProgrammeTimeslot.classes.entries()) {
        const docs = registrationState.find(
          ({ assignedClass }) => assignedClass === data
        );

        if (index === 0) {
          minCount = docs.length;
          minClassIndex = 0;
        } else {
          minCount = docs.length < minCount ? docs.length : minCount;
          minClassIndex = docs.length < minCount ? index : minClassIndex;
        }
      }

      let classIndex = minClassIndex;

      for await (const d of selectedData.items) {
        let assigned = false;
        for (
          let i = classIndex;
          i < selectedProgrammeTimeslot.classes.length;
          i++
        ) {
          const classResult = registrationState.find(
            ({ assignedClass }) =>
              assignedClass === selectedProgrammeTimeslot.classes[classIndex]
          );

          if (
            classResult.docs.length < selectedProgramme.classCapacity &&
            d.assignedClass === ""
          ) {
            d.assignedClass = selectedProgrammeTimeslot.classes[classIndex];
            // d.status = 0;

            const registrationDoc = registrationCollection.doc(d.id);
            registrationDoc.update(d);
            assigned = true;
            classIndex = classIndex + 1;

            if (classIndex === selectedProgrammeTimeslot.classes.length) {
              classIndex = 0;
            }
            break;
          }
        }

        // if (!assigned) {
        //   throw new Error(
        //     `Classes are full or registrations have already been assigned to a class.`
        //   );
        // }
      }
    } catch (err) {
      // console.debug(selectedProgramme);
    }

    // setTimeslotRegistrations(timeslotRegistrations);
    // setIsAssigningClass(true);
  }
}

export default function RegistrationList() {
  const rerenderQueriedAdminState = useQueriedAdminRerenderer();
  const lookupConfig = useLookupConfig();
  const registrationsState = useRegistrations();
  const transfersState = useTransfers();
  const rowFilters = useRowFilters();
  const isMounted = useIsMounted();

  // console.log({ registrationState });

  const { user } = useContext(AppGlobalContext);

  const isSuperAdmin = isSuperAdminRole(user);
  const isCatchOverallAdmin = isCATCHAdminRole(user);
  const isCatchParishAdmin = isCATCHParishAdminRole(user);

  const db = fire.firestore();
  const registrationCollection = db.collection(registrationCollectionName);
  const confirmationCollection = db.collection(
    catchConfirmationFormsCollectionName
  );
  const transfersCollection = db.collection(transfersCollectionName);

  // /** @type {{name: RegistrationStatus, value: number}[]} */
  // const radios = [
  //   { name: "pending", value: 1 },
  //   { name: "rejected", value: 2 },
  //   { name: "accepted", value: 0 },
  // ];

  // /** @type {RegistrationStatus} */
  // const initialStatus = radios[0].value;

  /** @type {RegistrationDatum[]} */
  const initialSelectedRows = [];
  const selectedRowsRef = useRef(initialSelectedRows);

  /**
   * @param {RegistrationAction} action
   */
  async function handleAction(action) {
    const selectedCount = selectedRowsRef.current.length;

    if (selectedCount === 0) {
      alert("Please select some rows first!");
      return Promise.resolve();
    }

    /** @type {RegistrationStatus} */
    let newStatus;

    const batch = db.batch();
    let updatedCount = 0;
    const toUpdateRegistrations = [];
    const toUpdateTransfers = [];

    // eslint-disable-next-line default-case
    switch (action) {
      case "moveTo":
        const selectedProgramme = [];
        const selectedParishIds = selectedRowsRef.current.map(
          ({ selectedParishId: id }) => id
        );
        const selectedProgrammeIds = selectedRowsRef.current.map(
          ({ programmeType: { id } }) => id
        );

        if (new Set(selectedParishIds).size > 1) {
          alert(
            `Unable to transfer Class. Please ensure that all selected rows have the same Parish.`
          );
          break;
        }

        if (new Set(selectedProgrammeIds).size > 1) {
          alert(
            `Unable to transfer Class. Please ensure that all selected rows have the same Programme.`
          );
          break;
        }

        setTransferModalIsOpen(true);
        break;
      case "accept":
      case "reject":
      case "unassign":
        newStatus = action === "unassign" ? "pending" : `${action}ed`;

        for (const row of selectedRowsRef.current) {
          let registrationUpdate = {};
          let transferUpdate = {};

          if (
            action === "reject" ||
            !registrationsState.data.some(
              ({ submittedBy, status }) =>
                status === newStatus && submittedBy === row.submittedBy
            )
          ) {
            registrationUpdate = {
              ...registrationUpdate,
              status: registrationStatusNameToStatus(newStatus),
            };
            transferUpdate = {
              ...transferUpdate,
              status: newStatus === "accepted" ? newStatus : `${newStatus}In`,
            };

            updatedCount++;
          }

          if (action === "accept" || action === "reject") {
            registrationUpdate = {
              ...registrationUpdate,
              processedAt: new Date(),
              processedBy: user.uid,
              processedByName: user.displayName,
            };
            transferUpdate = {
              ...transferUpdate,
              processedAt: new Date(),
              processedBy: user.uid,
              processedByName: user.displayName,
            };
          }

          if (action === "accept") {
            const arrayOfContactEmails = [];
            let selectedContacts = "";

            if (row.mainContact) {
              if (row.mainContact.name !== "") {
                selectedContacts = selectedContacts.concat(
                  row.mainContact.name
                );
                arrayOfContactEmails.push(row.mainContact.email);
              }
            }

            if (row.secondaryContact) {
              if (
                row.secondaryContact.exists &&
                !row.secondaryContact.emergencies
              ) {
                selectedContacts = selectedContacts.concat(
                  ` & ${row.secondaryContact.name}`
                );
                arrayOfContactEmails.push(row.secondaryContact.email);
              }
            }

            if (selectedContacts === "") {
              selectedContacts = "—";
            }

            const { parish: selectedParish, programme: selectedProgramme } =
              lookupConfig(row.selectedParishId, row.programmeType);

            let parishName = "—";
            let enquiryEmail = "—";

            if (selectedParish) {
              parishName = selectedParish.parish;
              enquiryEmail = selectedProgramme.enquiryEmail;
            }

            const emailHTML = `Dear ${selectedContacts},
            <br/><br/>
            We are glad to inform you that your registration${
              selectedProgramme.name ? ` for ${selectedProgramme.name}` : ""
            }${
              parishName ? ` in ${parishName}` : ""
            } has been successfully accepted, and your child will be starting ${
              row.sex === "Female" ? "her" : "his"
            } faith journey${
              selectedProgramme.registrationYear
                ? ` in ${selectedProgramme.registrationYear}`
                : " very soon"
            }. Please keep an eye out for a separate message from the Catechetical Ministry either via email or chat, whereby they will provide you with additional important details.${
              enquiryEmail
                ? ` For any clarifications or enquiries, please contact ${enquiryEmail}.`
                : ""
            }
            <br/><br/>
            God Bless,
            <br/>
            myCatholicSG Admin`;

            await sendGenericHTMLEmail({
              email: [...new Set(arrayOfContactEmails)],
              emailSubject: "Catechetical Registration Accepted",
              emailBody: emailHTML,
            });
          }

          if (action === "unassign") {
            registrationUpdate = { ...registrationUpdate, assignedClass: "" };
          }

          // console.log(row);

          if (Object.keys(registrationUpdate).length > 0) {
            toUpdateRegistrations.push([row.id, registrationUpdate]);
            batch.update(
              registrationCollection.doc(row.id),
              registrationUpdate
            );
          }
          if (Object.keys(transferUpdate).length > 0 && row.transfer?.id) {
            toUpdateRegistrations.push([row.transfer.id, registrationUpdate]);
            batch.update(
              transfersCollection.doc(row.transfer.id),
              transferUpdate
            );
          }
        }

        if (updatedCount === 0) {
          alert(
            `There are ${newStatus} registrations in conflict with your selected registrations.`
          );
        } else {
          await batch.commit();

          for (const [id_, update] of toUpdateRegistrations) {
            const docToUpdate = registrationsState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          for (const [id_, update] of toUpdateTransfers) {
            const docToUpdate = transfersState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          rerenderQueriedAdminState();

          alert(
            updatedCount === selectedCount
              ? "Update successful!"
              : `Update partially successful!\nThere are ${newStatus} registrations in conflict with some of your selected registrations.`
          );
        }

        break;
      case "waive":
        for (const row of selectedRowsRef.current) {
          const { programme } = lookupConfig(
            row.selectedParishId,
            row.programmeType
          );
          if (!programme) break;
          const payment = row.payments.find(
            ({ year, status }) =>
              year === programme.catecheticalYear &&
              status === "waiverRequested"
          );

          if (!!payment) {
            payment.status = "waiverApproved";
            batch.update(registrationCollection.doc(row.id), {
              payments: row.payments,
            });
            updatedCount++;
          }
        }

        if (updatedCount === 0) {
          alert(`The selected registrations are not requesting for waivers.`);
        } else {
          await batch.commit();
          alert(
            updatedCount === selectedCount
              ? "Waive successful!"
              : `Waive partially successful!\nThere are some registrations not requesting for waivers.`
          );
        }
        break;
      case "rejectwaiver":
        for (const row of selectedRowsRef.current) {
          const { programme } = lookupConfig(
            row.selectedParishId,
            row.programmeType
          );
          if (!programme) break;
          const payment = row.payments.find(
            ({ year, status }) =>
              year === programme.catecheticalYear &&
              status === "waiverRequested"
          );

          if (!!payment) {
            payment.status = "waiverRejected";
            batch.update(registrationCollection.doc(row.id), {
              payments: row.payments,
            });
            updatedCount++;
          }
        }

        if (updatedCount === 0) {
          alert(`The selected registrations are not requesting for waivers.`);
        } else {
          await batch.commit();
          alert(
            updatedCount === selectedCount
              ? "Waivers rejected."
              : `Waiver rejection partially successful.\nThere are some registrations not requesting for waivers.`
          );
        }
        break;
      case "retain":
        let registrationUpdate = {};

        for (const row of selectedRowsRef.current) {
          const retainedYears = row.retainedYears ?? [];
          const { selectedParishId, programmeType } = row;

          const catecheticalYear = lookupConfig(selectedParishId, programmeType)
            ?.programme?.catecheticalYear;
          if (!catecheticalYear || retainedYears.includes(catecheticalYear))
            continue;

          retainedYears.push(catecheticalYear);
          registrationUpdate = { ...registrationUpdate, retainedYears };

          if (Object.keys(registrationUpdate).length > 0) {
            updatedCount++;
            toUpdateRegistrations.push([row.id, registrationUpdate]);
            batch.update(
              registrationCollection.doc(row.id),
              registrationUpdate
            );
          }
        }

        if (updatedCount === 0) {
          alert(`Failed to update.`);
        } else {
          await batch.commit();

          for (const [id_, update] of toUpdateRegistrations) {
            const docToUpdate = registrationsState.data.find(
              ({ id }) => id === id_
            );
            if (docToUpdate)
              for (const [key, value] of Object.entries(update))
                docToUpdate[key] = value;
          }

          rerenderQueriedAdminState();

          alert(
            updatedCount === selectedCount
              ? `Successful update: ${updatedCount} ${
                  updatedCount === 1 ? "child" : "children"
                } retained.`
              : `Partially successful update: ${updatedCount} out of ${selectedCount} ${
                  selectedCount === 1 ? "child" : "children"
                } retained.`
          );
        }

        break;
      case "email":
        let emails = "mailto:";
        if (selectedRowsRef.current.length === 1) {
          emails += selectedRowsRef.current[0].mainContact.email;

          if (
            selectedRowsRef.current[0].secondaryContact.exists &&
            selectedRowsRef.current[0].secondaryContact.emergencies === false
          ) {
            emails += `;${selectedRowsRef.current[0].secondaryContact.email}`;
          }
        } else {
          emails += "?bcc=";
          selectedRowsRef.current.forEach((row, index) => {
            emails += row.mainContact.email;

            if (
              row.secondaryContact.exists &&
              row.secondaryContact.emergencies === false
            ) {
              emails += `;${row.secondaryContact.email}`;
            }

            if (index < selectedRowsRef.current.length - 1) {
              emails += ";";
            }
          });
        }

        window.location.href = emails;
        break;
    }
    return Promise.resolve();
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transferModalIsOpen, setTransferModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState([]);

  const [cachedRegistrations, setCachedRegistrations] = useState([]);

  useEffect(() => {
    setCachedRegistrations(registrationsState.data || []); // in case there is no registration data
  }, [registrationsState.data]);

  const [
    isDownloadingGodfatherBaptismCert,
    setIsDownloadingGodfatherBaptismCert,
  ] = useState(false);
  const [
    isDownloadingGodfatherConfirmationCert,
    setIsDownloadingGodfatherConfirmationCert,
  ] = useState(false);
  const [
    isDownloadingGodmotherBaptismCert,
    setIsDownloadingGodmotherBaptismCert,
  ] = useState(false);
  const [
    isDownloadingGodmotherConfirmationCert,
    setIsDownloadingGodmotherConfirmationCert,
  ] = useState(false);

  const isDownloading = {
    godfatherBaptismCert: isDownloadingGodfatherBaptismCert,
    godfatherConfirmationCert: isDownloadingGodfatherConfirmationCert,
    godmotherBaptismCert: isDownloadingGodmotherBaptismCert,
    godmotherConfirmationCert: isDownloadingGodmotherConfirmationCert,
  };
  const setIsDownloading = {
    godfatherBaptismCert: setIsDownloadingGodfatherBaptismCert,
    godfatherConfirmationCert: setIsDownloadingGodfatherConfirmationCert,
    godmotherBaptismCert: setIsDownloadingGodmotherBaptismCert,
    godmotherConfirmationCert: setIsDownloadingGodmotherConfirmationCert,
  };

  const openModal = async (item) => {
    setSelectedItem(item);
    // await new Promise((r) => setTimeout(r, 3000)); // Manual delay for testing purposes
    // console.log(item.id); // for testing

    if (isCatchParishAdmin) {
      const { data: { ok, data: { confirmationQuery = [] } = {} } = {} } =
        await getCatchConfirmations({
          uid: user.uid,
          parish: user.parish,
          childId: item.child.uid,
        });

      if (ok) {
        item.confirmationDetails = confirmationQuery[0];
      } else {
        return;
      }
    } else if (isCatchOverallAdmin || isSuperAdmin) {
      const selectedConfirmation = await confirmationCollection
        .where("child.uid", "==", item.child.uid)
        .get();

      if (!selectedConfirmation.empty) {
        item.confirmationDetails = selectedConfirmation.docs[0].data();
      }
    } else {
      return;
    }

    setModalIsOpen(true);

    if (item.notes) {
      item.notes.map((note) => {
        if (note.edit) {
          note.edit = false;
        }
      });
      setSelectedNotes(item.notes);
    }
  };

  const updateNotes = (itemId, newNotes) => {
    setCachedRegistrations((prev) => {
      return prev.map((item) =>
        item.id === itemId
          ? {
              ...item,
              notes: newNotes,
            }
          : item
      );
    });
  };

  const closeModal = () => {
    if (selectedItem?.id) {
      // make conditional in case the closeModal is being called from elsewhere
      updateNotes(selectedItem.id, selectedNotes);
    }

    setModalIsOpen(false);
    setTransferModalIsOpen(false);
    setSelectedItem(null);
    setSelectedNotes([]);
  };

  const dataProps = cachedRegistrations
    .filter((registrationDatum) => {
      const { selectedParishId, programmeType, status, registrationType } =
        registrationDatum;
      const currentLevel = getCurrentLevel({
        registrationDatum,
        lookupConfig,
      });

      return (
        ("" === rowFilters.parish || selectedParishId === rowFilters.parish) &&
        ("" === rowFilters.programme ||
          (typeof programmeType === "object"
            ? programmeType.id
            : programmeType) === rowFilters.programme) &&
        ("" === rowFilters.level ||
          currentLevel?.toString() === rowFilters.level) &&
        registrationStatusFilterOptions
          .map(({ value }) => value)
          .includes(status) &&
        !(
          registrationType === "transfer" &&
          status !== registrationStatusNameToStatus("accepted")
        ) &&
        ("" === rowFilters.status || status?.toString() === rowFilters.status)
      );
    })
    .map((d) => ({
      ...d,
      catecheticalYear: lookupConfig(d.selectedParishId, d.programmeType)
        ?.programme?.catecheticalYear,
    }));

  // useEffect(() => {
  //   console.log({
  //     filter: registrationFilters,
  //     pre: registrationState.data,
  //     post: dataProps,
  //   });
  // }, [registrationFilters, registrationState.data]);

  async function transferClass(selectedItems, updateObj) {
    const batch = db.batch();
    let updatedDocs = 0;

    for (const row of selectedItems) {
      if (row.assignedClass === updateObj.assignedClass) continue;

      const originalRow = registrationsState.data.find(
        ({ id }) => id === row.id
      );
      originalRow.timeslot = updateObj.timeslot;
      originalRow.assignedClass = updateObj.assignedClass;

      const registrationDoc = registrationCollection.doc(row.id);
      batch.update(registrationDoc, updateObj);
      updatedDocs++;
    }

    rerenderQueriedAdminState();
    closeModal();

    if (updatedDocs === 0) {
      console.log(`Nothing to do (no class change).`);
      return;
    }
    await batch.commit();
    alert(`Assigned classes to ${updatedDocs} individual(s) successfully.`);
  }

  const viewButtonProps = {
    openModal,
    selectedItem,
  };
  const listProps = (
    isSuperAdmin || isCatchOverallAdmin
      ? getOverallAdminListProps
      : getParishAdminListProps
  )(lookupConfig, viewButtonProps);

  return (
    <>
      <div
        className={modalIsOpen ? "d-none" : ""} //Hides table when printing registration details
      >
        <AbstractList
          {...listProps}
          id="registration-table"
          statusDictionary={guardianRegistrationStatusDictionary}
          radios={[]}
          currentStatus={rowFilters.status}
          rawDataProps={dataProps}
          selectedRowsRef={selectedRowsRef}
          handleAction={handleAction}
          handleAssignClass={(selectedItems) =>
            autoAssignClass(selectedItems, lookupConfig, registrationsState)
          }
          // handleSelectedStatus={handleStatus}
        />
      </div>

      <Modal
        className="p-0"
        dialogClassName="modal-fullscreen"
        show={modalIsOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="font-weight-bold noprint">
          <div className="w-100 d-flex justify-content-center">
            <Modal.Title>{selectedItem?.name}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {selectedItem?.confirmationDetails && (
              <div className="w-100">
                <div className="mb-sm-3">
                  <h5>Confirmation Details</h5>
                </div>
                <span className="font-weight-bold">
                  Receiving Confirmation?
                </span>
                <div className="mb-sm-3">
                  {selectedItem.confirmationDetails &&
                  selectedItem.confirmationDetails.isReceiving
                    ? "Yes"
                    : "No"}
                </div>
                {selectedItem.confirmationDetails.isReceiving === true ? (
                  <>
                    <span className="font-weight-bold">Confirmation Name</span>
                    <div className="mb-sm-3">
                      {selectedItem.confirmationDetails &&
                      selectedItem.confirmationDetails.confirmationName
                        ? selectedItem.confirmationDetails.confirmationName
                        : "-"}
                    </div>
                    <span className="font-weight-bold">
                      Full Name (for Confirmation Certificate)
                    </span>
                    <div className="mb-sm-3">
                      {selectedItem.confirmationDetails &&
                      selectedItem.confirmationDetails.printedConfirmationName
                        ? selectedItem.confirmationDetails
                            .printedConfirmationName
                        : "-"}
                    </div>
                    <div className="mb-sm-3">
                      <h5>Sponsor Details</h5>
                    </div>
                    <Row className="border-bottom py-2">
                      <Col xs={12} sm={6}>
                        <span className="font-weight-bold">Godfather</span>
                        <br />
                        {!selectedItem.confirmationDetails?.godfather?.name ? (
                          <span className="text-muted">N/A</span>
                        ) : (
                          <>
                            {selectedItem?.confirmationDetails?.godfather
                              ?.name ?? "-"}
                            <ul>
                              <li>
                                Baptism Certificate
                                <br />
                                <Button
                                  disabled={isDownloading.godfatherBaptismCert}
                                  variant="secondary"
                                  className="text-wrap text-dark"
                                  style={{
                                    backgroundColor: "#f3f3f3",
                                    borderColor: "#f3f3f3",
                                  }}
                                  onClick={async () => {
                                    setIsDownloading.godfatherBaptismCert(true);
                                    await downloadCert(
                                      selectedItem.confirmationDetails
                                        ?.godfather?.baptism?.cert,
                                      user
                                    );
                                    setIsDownloading.godfatherBaptismCert(
                                      false
                                    );
                                  }}
                                >
                                  {isDownloading.godfatherBaptismCert && (
                                    <div
                                      className="spinner-border mr-1"
                                      role="status"
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  <AttachFileOutlinedIcon
                                    className="mr-1"
                                    style={{ color: "#7c7c7c" }}
                                  />
                                  {selectedItem.confirmationDetails?.godfather
                                    ?.baptism?.cert.path
                                    ? selectedItem.confirmationDetails
                                        ?.godfather?.baptism?.cert.name
                                    : "—"}
                                </Button>
                              </li>
                              <li>
                                Confirmation Certificate
                                <br />
                                <Button
                                  disabled={
                                    isDownloading.godfatherConfirmationCert
                                  }
                                  variant="secondary"
                                  className="text-wrap text-dark"
                                  style={{
                                    backgroundColor: "#f3f3f3",
                                    borderColor: "#f3f3f3",
                                  }}
                                  onClick={async () => {
                                    setIsDownloading.godfatherConfirmationCert(
                                      true
                                    );
                                    await downloadCert(
                                      selectedItem.confirmationDetails
                                        ?.godfather?.confirmation?.cert,
                                      user
                                    );
                                    setIsDownloading.godfatherConfirmationCert(
                                      false
                                    );
                                  }}
                                >
                                  {isDownloading.godfatherConfirmationCert && (
                                    <div
                                      className="spinner-border mr-1"
                                      role="status"
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  <AttachFileOutlinedIcon
                                    className="mr-1"
                                    style={{ color: "#7c7c7c" }}
                                  />
                                  {selectedItem.confirmationDetails?.godfather
                                    ?.confirmation?.cert.path
                                    ? selectedItem.confirmationDetails
                                        ?.godfather?.confirmation?.cert.name
                                    : "—"}
                                </Button>
                              </li>
                            </ul>
                          </>
                        )}
                      </Col>
                      <Col xs={12} sm={6} className="border-left">
                        <span className="font-weight-bold">Godmother</span>
                        <br />
                        {!selectedItem.confirmationDetails?.godmother?.name ? (
                          <span className="text-muted">N/A</span>
                        ) : (
                          <>
                            {selectedItem.confirmationDetails?.godmother
                              ?.name ?? "-"}
                            <ul>
                              <li>
                                Baptism Certificate:
                                <br />
                                <Button
                                  disabled={isDownloading.godmotherBaptismCert}
                                  variant="secondary"
                                  className="text-wrap text-dark"
                                  style={{
                                    backgroundColor: "#f3f3f3",
                                    borderColor: "#f3f3f3",
                                  }}
                                  onClick={async () => {
                                    setIsDownloading.godmotherBaptismCert(true);
                                    await downloadCert(
                                      selectedItem.confirmationDetails
                                        ?.godmother?.baptism?.cert,
                                      user
                                    );
                                    setIsDownloading.godmotherBaptismCert(
                                      false
                                    );
                                  }}
                                >
                                  {isDownloading.godmotherBaptismCert && (
                                    <div
                                      className="spinner-border mr-1"
                                      role="status"
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  <AttachFileOutlinedIcon
                                    className="mr-1"
                                    style={{ color: "#7c7c7c" }}
                                  />
                                  {selectedItem.confirmationDetails?.godmother
                                    ?.baptism?.cert.path
                                    ? selectedItem.confirmationDetails
                                        ?.godmother?.baptism?.cert.name
                                    : "—"}
                                </Button>
                              </li>
                              <li>
                                Confirmation Certificate:
                                <br />
                                <Button
                                  disabled={
                                    isDownloading.godmotherConfirmationCert
                                  }
                                  variant="secondary"
                                  className="text-wrap text-dark"
                                  style={{
                                    backgroundColor: "#f3f3f3",
                                    borderColor: "#f3f3f3",
                                  }}
                                  onClick={async () => {
                                    setIsDownloading.godmotherConfirmationCert(
                                      true
                                    );
                                    await downloadCert(
                                      selectedItem.confirmationDetails
                                        ?.godmother?.confirmation?.cert,
                                      user
                                    );
                                    setIsDownloading.godmotherConfirmationCert(
                                      false
                                    );
                                  }}
                                >
                                  {isDownloading.godmotherConfirmationCert && (
                                    <div
                                      className="spinner-border mr-1"
                                      role="status"
                                      style={{
                                        width: "1.5rem",
                                        height: "1.5rem",
                                      }}
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}
                                  <AttachFileOutlinedIcon
                                    className="mr-1"
                                    style={{ color: "#7c7c7c" }}
                                  />
                                  {selectedItem.confirmationDetails?.godmother
                                    ?.confirmation?.cert.path
                                    ? selectedItem.confirmationDetails
                                        ?.godmother?.confirmation?.cert.name
                                    : "—"}
                                </Button>
                              </li>
                            </ul>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
            <RegistrationDetails
              {...{
                user,
                registrationCollection,
                transfersCollection,
                selectedItem,
                setSelectedItem,
                selectedNotes,
                setSelectedNotes,
                currentContext: "registration",
                transferDocId: null,
              }}
            />
          </Container>
        </Modal.Body>
      </Modal>

      {transferModalIsOpen && (
        <MoveClassModal
          transferModalIsOpen={transferModalIsOpen}
          closeModal={closeModal}
          selectedItems={selectedRowsRef.current}
          transferClass={transferClass}
        />
      )}
    </>
  );
}
